import { useState } from 'react'
import './development.css'
const Development = () => {

  const [wrong, setWrong] = useState(false)
  const passwordHandler = (e) => {
    e.preventDefault()
    const value = e.target.password.value
    if (value === 'pcqatester') {
      localStorage.setItem('development', value)
      window.location.href = '/'
    } else {
      setWrong(true)
    }
  }
  return (
    <div className="development_section">
      <div className="container">
        <div className="row development_row">
          <div className="col development_col">
            <div className="development_form">
              <p>The site is under development to view enter the password.</p>
              <form onSubmit={passwordHandler}>
                <input name="password" type="text" id="" />
                {wrong &&
                  <div className="alert alert-danger mt-3">
                    Password is wrong
                  </div>
                }
                <button type='submit'>Submit</button>
              </form>
            </div>
          </div>

        </div>

      </div>
    </div>

  )
}
export default Development