import React, { useEffect, useState } from "react";
import { Typography, Modal, LinearProgress, Grid, MenuItem, Select, FormControl } from "@mui/material";
import TopGames from "./TopGames";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import { getAll } from "../../api";
import { toast } from "react-toastify";
import Chart from "./Chart/Chart";
import { math } from "../../helpers/functions";
// Create a custom theme with customized warning color
const theme = createTheme({
  palette: {
    warning: {
      main: "#f0dc04",
    },
  },
});

const noReviewText = 'Be the fast reviewer'

const GameMedia = ({ gamedata }) => {
  const [game, setGame] = useState()
  const [oldGame, setOldGame] = useState()

  useEffect(() => {
    setGame(gamedata)
    setOldGame(gamedata)
  }, [gamedata])

  const [modalOpen, setModalOpen] = useState(false);

  const [ratings, setRatings] = useState([]);

  const handleOpenModal = (type) => {
    setModalOpen(true);
    let gameplay = 0
    let story = 0
    let visuals = 0
    let sound = 0
    let controls = 0
    let replayability = 0
    let originality = 0
    let value = 0
    let technicalPerformance = 0
    let accessibility = 0


    let objName
    let total
    if (type === 'User') {
      objName = "userObj"
      total = "totalUserReview"
    } else if (type === 'Critic') {
      objName = "criticObj"
      total = "totalCriticReview"
    } else {
      objName = "overallObj"
      total = "totalReviews"
    }

    if (game[total] > 0) {
      gameplay = math((game[objName].gameplay / game[total]) * 10)
      story = math((game[objName].story / game[total]) * 10)
      visuals = math((game[objName].visuals / game[total]) * 10)
      sound = math((game[objName].sound / game[total]) * 10)
      controls = math((game[objName].controls / game[total]) * 10)
      replayability = math((game[objName].replayability / game[total]) * 10)
      originality = math((game[objName].originality / game[total]) * 10)
      value = math((game[objName].value / game[total]) * 10)
      technicalPerformance = math((game[objName].technicalPerformance / game[total]) * 10)
      accessibility = math((game[objName].accessibility / game[total]) * 10)

    }

    setRatings(
      [
        { item: "Game Play", rating: gameplay },
        { item: "Story", rating: story },
        { item: "Visuals", rating: visuals },
        { item: "Sounds", rating: sound },
        { item: "Controls", rating: controls },
        {
          item: "Replayability",
          rating: replayability,
        },
        {
          item: "Originality",
          rating: originality,
        },
        { item: "Value", rating: value },
        {
          item: "Technical Performance",
          rating: technicalPerformance,
        },
        {
          item: "Accessibility",
          rating: accessibility,
        }
      ]
    )
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };



  const difficultHandler = (total, divide) => {

    if (divide > 0) {
      const difficult = total / divide
      const average = 10 / 3
      if (difficult <= (average * 1)) {
        return 'Easy'
      }
      if (difficult <= (average * 2)) {
        return 'Moderate'
      }
      if (difficult <= (average * 3)) {
        return 'Hard'
      }
    } else {
      return noReviewText
    }

  }

  const [version, setVersion] = useState("Filter By Version");

  const versionHandler = async (e) => {
    const value = e.target.value
    setVersion(value)
    if (value !== "Filter By Version") {
      try {
        const { data } = await getAll('/reviews/game/filter/' + game.gameURL, {
          version: value,
          type: "version"
        })

        if (data.success) {
          let copyGame = {
            userObj: {},
            criticObj: {},
            overallObj: {}
          }

          copyGame.userObj.gameplay = 0
          copyGame.userObj.story = 0
          copyGame.userObj.visuals = 0
          copyGame.userObj.sound = 0
          copyGame.userObj.controls = 0
          copyGame.userObj.replayability = 0
          copyGame.userObj.originality = 0
          copyGame.userObj.value = 0
          copyGame.userObj.technicalPerformance = 0
          copyGame.userObj.accessibility = 0
          copyGame.userObj.hourlyPlayed = 0
          copyGame.userObj.difficultyLevel = 0
          copyGame.userRating = 0
          copyGame.totalUserReview = 0
          copyGame.userRating = 0

          copyGame.criticObj.gameplay = 0
          copyGame.criticObj.story = 0
          copyGame.criticObj.visuals = 0
          copyGame.criticObj.sound = 0
          copyGame.criticObj.controls = 0
          copyGame.criticObj.replayability = 0
          copyGame.criticObj.originality = 0
          copyGame.criticObj.value = 0
          copyGame.criticObj.technicalPerformance = 0
          copyGame.criticObj.accessibility = 0
          copyGame.criticObj.hourlyPlayed = 0
          copyGame.criticObj.difficultyLevel = 0
          copyGame.criticRating = 0
          copyGame.totalCriticReview = 0
          copyGame.criticRating = 0


          copyGame.overallObj.gameplay = 0
          copyGame.overallObj.story = 0
          copyGame.overallObj.visuals = 0
          copyGame.overallObj.sound = 0
          copyGame.overallObj.controls = 0
          copyGame.overallObj.replayability = 0
          copyGame.overallObj.originality = 0
          copyGame.overallObj.value = 0
          copyGame.overallObj.technicalPerformance = 0
          copyGame.overallObj.accessibility = 0
          copyGame.overallObj.hourlyPlayed = 0
          copyGame.overallObj.difficultyLevel = 0
          copyGame.rating = 0
          copyGame.totalReviews = 0


          data.data.map((item, index) => {
            if (item.type === 'User') {
              copyGame.userObj.gameplay += item.gameplay
              copyGame.userObj.story += item.story
              copyGame.userObj.visuals += item.visuals
              copyGame.userObj.sound += item.sound
              copyGame.userObj.controls += item.controls
              copyGame.userObj.replayability += item.replayability
              copyGame.userObj.originality += item.originality
              copyGame.userObj.value += item.value
              copyGame.userObj.technicalPerformance += item.technicalPerformance
              copyGame.userObj.accessibility += item.accessibility
              copyGame.userObj.hourlyPlayed += item.hourlyPlayed
              copyGame.userObj.difficultyLevel += item.difficultyLevel
              copyGame.userRating += item.rating
              copyGame.totalUserReview += 1
            }

            else {
              copyGame.criticObj.gameplay += item.gameplay
              copyGame.criticObj.story += item.story
              copyGame.criticObj.visuals += item.visuals
              copyGame.criticObj.sound += item.sound
              copyGame.criticObj.controls += item.controls
              copyGame.criticObj.replayability += item.replayability
              copyGame.criticObj.originality += item.originality
              copyGame.criticObj.value += item.value
              copyGame.criticObj.technicalPerformance += item.technicalPerformance
              copyGame.criticObj.accessibility += item.accessibility
              copyGame.criticObj.hourlyPlayed += item.hourlyPlayed
              copyGame.criticObj.difficultyLevel += item.difficultyLevel
              copyGame.criticRating += item.rating
              copyGame.totalCriticReview += 1
            }


            copyGame.overallObj.gameplay += item.gameplay
            copyGame.overallObj.story += item.story
            copyGame.overallObj.visuals += item.visuals
            copyGame.overallObj.sound += item.sound
            copyGame.overallObj.controls += item.controls
            copyGame.overallObj.replayability += item.replayability
            copyGame.overallObj.originality += item.originality
            copyGame.overallObj.value += item.value
            copyGame.overallObj.technicalPerformance += item.technicalPerformance
            copyGame.overallObj.accessibility += item.accessibility
            copyGame.overallObj.hourlyPlayed += item.hourlyPlayed
            copyGame.overallObj.difficultyLevel += item.difficultyLevel
            copyGame.rating += item.rating
            copyGame.totalReviews += 1

            if ((data.data.length - 1) === index) {
              if (copyGame.totalReviews > 0) {
                copyGame.rating = (copyGame.rating / copyGame.totalReviews).toFixed(1);
              }

              if (copyGame.totalUserReview > 0) {
                copyGame.userRating = (copyGame.userRating / copyGame.totalUserReview).toFixed(1)
              }

              if (copyGame.criticRating > 0) {
                copyGame.criticRating = (copyGame.criticRating / copyGame.totalCriticReview).toFixed(1);
              }


            }
          })
          setGame({ ...game, ...copyGame })
        }

      } catch (error) {
        toast.error(error.message)
      }
    } else {
      setGame(oldGame)
    }
  }

  const [graph, setGraph] = useState("Filter By Graph")

  const [graphList, setgraphList] = useState([])
  const graphHandler = async (e) => {
    const value = e.target.value
    setGraph(value)
    const { data } = await getAll('/reviews/game/filter/' + game.gameURL, {
      type: "date",
      graphType: value
    })
    if (data.success) setgraphList(data.data)
  }

  return (<>
    {(game && game.criticObj) &&
      <div className="container py-2">

        <div className="row my-3 d-flex text-right game_score review_filter_box" style={{
          textAlign: 'right'
        }}>

          <div className="col">
            <FormControl className="custom_select" size="small" style={{
              marginRight: "10px"
            }}>
              <Select
                value={graph}
                onChange={graphHandler}
                label="Filter By Graph"
                className="select_box_main"
              >
                <MenuItem value="Filter By Graph" key="Filter By Graph">Filter By Graph</MenuItem>
                <MenuItem value="Graph By Day" key="Graph by Day">Graph by Day</MenuItem>
                <MenuItem value="Graph By Month" key="Graph By Month">Graph By Month</MenuItem>
                <MenuItem value="Graph By Year" key="Graph By Year">Graph By Year</MenuItem>
              </Select>
            </FormControl>

            <FormControl className="custom_select" size="small">
              <Select
                value={version}
                onChange={versionHandler}
                label="Filter By Version"
                className="select_box_main"
              >
                <MenuItem value="Filter By Version" key="Filter By Version">Filter By Version</MenuItem>
                {game.allVersion.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
        </div>

        {(graph !== 'Filter By Graph' && graphList.length > 0) &&
          <div className="row">
            <Chart graphList={graphList} type={graph} />
          </div>
        }

        <div className="row my-3 d-flex align-items-stretch game_score">
          {[
            { d: difficultHandler(game.userObj.difficultyLevel, game.totalUserReview), t: "User Dificulty Level" },
            { d: difficultHandler(game.criticObj.difficultyLevel, game.totalCriticReview), t: "Critic Dificulty Level" },
            { d: game.overallObj.hourlyPlayed ? game.overallObj.hourlyPlayed : 0 + " Hours", t: "Hours of Game Play" },
          ].map((game, index) => (
            <div className="col-lg-4 col-md-4 col-6 mb-0 mt-0" key={index}>
              <div
                style={{ height: "120px" }}
                // role="button"
                className="thumbnail-wrapper light-bg  text-center  rounded-3 flex-wrap d-flex align-items-center justify-content-center "
              >
                <div>
                  <h4 className="col-12 text-muted">{game.t}</h4>
                  <p className="col-12">{game.d}</p>
                </div>
              </div>
            </div>
          ))}


          {[
            { d: game.totalCriticReview > 0 ? math(game.criticRating) + " out of 10" : noReviewText, t: "Player Critic Score", n: 'Critic' },
            { d: game.totalUserReview > 0 ? math(game.userRating) + " out of 10" : noReviewText, t: "User Score", n: 'User' },
            { d: game.totalReviews > 0 ? math(game.rating) + " out of 10" : noReviewText, t: "Overall Score", n: 'Overall' },
          ].map((game, index) => (
            <div className="col-lg-4 col-md-4 col-6 mb-0 mt-0" key={index}>
              <div
                style={{ height: "120px" }}
                onClick={() => handleOpenModal(game.n)}
                role="button"
                className="thumbnail-wrapper light-bg  text-center  rounded-3 flex-wrap d-flex align-items-center justify-content-center "
              >
                <div>
                  <h4 className="col-12 text-muted">{game.t}</h4>
                  <p className="col-12">{game.d}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-lg-12">

            <h2 className="mt-4 mb-2">About</h2>
            <div className="mb-2 row">
              <p className="text-white col-12 ">{game.gameAbout}</p>
            </div>

            <h6 className="mt-4 mb-2">Publisher: <span>{game.publisher}</span></h6>
            <h6 className="mt-4 mb-2">Developer: <span>{game.developer}</span></h6>
            <h6 className="mt-4 mb-2">Currnet Version: <span>{game.version}</span></h6>
            <h6 className="mt-4 mb-2">Available Versions: <span>{game.allVersion.join(' | ')}</span></h6>
            <h6 className="mt-4 mb-2">Available Platforms: <span>{game.platform.join(', ')}</span></h6>
            {game.link && <h6 className="mt-4 mb-2">Download Link: <span>{game.link}</span></h6>}
            {game.is18Plus && <h6 className="mt-4 mb-2">Age:  <span>18+</span></h6>}



          </div>

        </div>

        <TopGames text={"Top Games"} />


        <ThemeProvider theme={theme}>
          <Modal open={modalOpen} onClose={handleCloseModal} className="dynamic_single_model">

            <div
              className="light-bg p-4 rounded-4 col-lg-5 col-md-6 col-10"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <div className="close" onClick={handleCloseModal}>
                <CloseIcon fontSize={"medium"} style={{ cursor: 'pointer' }} />
              </div>
              <h5 className="text-muted">Ratings</h5>
              {/* <p className="text-muted">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. dolor
                temporibus eveniet.
              </p> */}
              <Grid container spacing={2}>
                {ratings.map((rating, index) => (
                  <>
                    <Grid className="my-2" item lg={6} md={6} sm={6} xs={12} key={index}>
                      <div className="d-flex justify-content-between">
                        <Typography gutterBottom>{rating.item}</Typography>
                        <Typography variant="body2" className="text-muted">
                          {rating.rating}%
                        </Typography>
                      </div>
                      <LinearProgress
                        variant="determinate"
                        color="warning"
                        value={rating.rating}
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
          </Modal>
        </ThemeProvider>


      </div>
    }
  </>);
};

export default GameMedia;
