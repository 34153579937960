import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HttpsIcon from "@mui/icons-material/Https";

import {
  Card,
  Button,
  TextField,

  FormControl,
  Box,
  Modal,
  InputAdornment,
  IconButton,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { updateUserProfile, getUserProfile, updateUserPassword } from "../api";
import Header from "../Components/Header";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
};

export default function Settings() {
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [Email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [UserName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [isProcessing, setisProcessing] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserProfile();
        setEmail(response.data.email);
        setUserName(response.data.username);
        setType(response.data.type);
      } catch (error) {
        console.error("Error fetching current user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changePassword = async () => {
    if (!OldPassword || !NewPassword) {
      toast.error("Input Fields Can't be Empty");
      return;
    }
    setisProcessing(true);
    try {
      const response = await updateUserPassword({
        oldPassword: OldPassword,
        newPassword: NewPassword,
      });

      if (response.status == 200) {
        toast.success("Password updated");
      } else {
        const data = await response.json();
        toast.error(data.error || "Error updating password");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response.data.error || "Error updating password");
    } finally {
      setisProcessing(false);
    }
  };

  const [isEmail, setIsEmail] = useState(false)
  const [isUsername, setIsUsername] = useState(false)
  const updateUser = async () => {
    if (!OldPassword) {
      toast.error("Old Password Cant be Empty");
      return;
    }
    setisProcessing(true);
    const email = Email;
    const name = UserName;
    const password = OldPassword;
    try {
      const { data } = await updateUserProfile({
        userName: name,
        email: email,
        oldPassword: password,
      });

      if (data.success) {
        toast.success("User Information Updated");
        localStorage.removeItem('playercritic_user_User')
        localStorage.removeItem('playercritic_user_Token')
        window.location.href = '/login'
      } else if (data.isUsername || data.isEmail) {
        setIsUsername(data.isUsername)
        setIsEmail(data.isEmail)
      }
      else {
        toast.error(data.error || "Error updating user information");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response.data.error || "Error updating user information"
      );
    } finally {
      setisProcessing(false);
    }
  };

  return (
    <>
      <Header />
      <div
        id="content-wrapper  "
        className="container  mt-5 d-flex flex-column"
      >
        {/* Main Content */}
        <div id="content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />

          <Card
            style={{ padding: 40, borderRadius: 10 }}
            className="light-bg mx-4"
          >
            <div className="row mt-2">
              <div className=" mb-4 py-4 d-flex justify-content-start align-items-center">
                <Button
                  endIcon={<HttpsIcon />}
                  className=""
                  variant="contained"
                  size="large"
                  component="label"
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  Change Password
                </Button>
              </div>

              <div className="col-sm-6">
                <TextField
                  className="col-12"
                  id="userName"
                  label="User Name"
                  variant="outlined"
                  value={UserName}
                  onChange={(e) => setUserName(e.target.value)}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                />
                {isUsername && <p className="alert alert-danger mt-2">This username is already taken</p>}
              </div>


              <div className="col-sm-6">
                <TextField
                  className="col-12"
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                />
                {isEmail && <p className="alert alert-danger mt-2">This email is already taken</p>}
              </div>
              <div className="col-sm-6 mt-4">
                <TextField
                  className="col-12"
                  id="old_Password"
                  label="Enter Old Password"
                  variant="outlined"
                  type={showOldPassword ? "text" : "password"}
                  value={OldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="text-white"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showOldPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {type === 'Employee' &&
                <div className="col-sm-6 employee_status">
                  <TextField
                    disabled
                    className="col-12"
                    id="type"
                    label="Role"
                    variant="outlined"
                    value={type}
                    InputLabelProps={{
                      className: "text-white",
                    }}
                    style={{
                      color: "#fff",
                    }}
                  />
                </div>
              }
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="light-bg" sx={style}>
                  <form>
                    <Box style={{ display: "flex", justifyContent: "end" }}>
                      <CloseIcon
                        onClick={handleClose}
                        style={{ cursor: "pointer", color: "gray" }}
                      />
                    </Box>
                    <h2 className="mb-4 text-white" mb={3}>
                      Change Password
                    </h2>

                    <FormControl fullWidth>
                      <TextField
                        className="mb-4"
                        id="oldPassword"
                        label="Enter Old Password"
                        variant="outlined"
                        type={showOldPassword ? "text" : "password"}
                        fullWidth
                        value={OldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        InputLabelProps={{
                          className: "text-white",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="text-white"
                                onClick={() =>
                                  setShowOldPassword(!showOldPassword)
                                }
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                              >
                                {showOldPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        className="mb-4"
                        id="newPassword"
                        label="Enter New Password"
                        variant="outlined"
                        type={showNewPassword ? "text" : "password"}
                        fullWidth
                        value={NewPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputLabelProps={{
                          className: "text-white",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className="text-white"
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>

                    <Button
                      fullWidth
                      className="mt-2 muibtn"
                      variant="contained"
                      color="primary"
                      onClick={() => changePassword()}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <CircularProgress sx={{ color: "white" }} size={27} />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </form>
                </Box>
              </Modal>

              <div className="col-12 d-flex justify-content-center mt-5">
                <Button
                  variant="contained"
                  size="large"
                  component="label"
                  onClick={() => updateUser()}
                  disabled={isProcessing}
                >
                  {isProcessing ? (
                    <CircularProgress sx={{ color: "white" }} size={27} />
                  ) : (
                    " Save Changes"
                  )}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}