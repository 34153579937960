import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import UserContext from "../Context/UserContext";
import jwtDecode from "jwt-decode";

export default function HandleAuthLogin() {
  const { setUserAndToken } = useContext(UserContext);
  const { token } = useParams();
  useEffect(() => {
    if (token) {
      try {
        localStorage.setItem("playercritic_user_User", JSON.stringify(token));
        const decoded = jwtDecode(token);
        localStorage.removeItem("playercritic_user_Token");
        localStorage.removeItem("playercritic_user_User");
        localStorage.setItem("playercritic_user_Token", token);
        localStorage.setItem("playercritic_user_User", decoded);
        setUserAndToken(decoded.user, token);
      } catch (error) {
        console.log(error);
      }
      window.location.href = "/";
    }
  }, [token]);

  return <div className="text-white">Redirecting...</div>;
}
