import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Forget from "./Pages/ForgetPassword";
import PasswordReset from "./Pages/reset-password";
import ProtectedRoute from "./Context/ProtectedRoute";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme/theme";
import HomePage from "./Pages/HomePage";
import SubmitGame from "./Pages/SubmitGame";
import GameReview from "./Pages/GameReview";
import Contact from "./Pages/Contact";
import ScrollToTop from "./Components/ScrollToTop";
import { UserProvider } from "./Context/UserContext";
import HandleAuthLogin from "./Context/HandleAuthLogin";
import SearchResult from "./Pages/SearchResult";
import Settings from "./Pages/Settings";
import MyGames from "./Pages/MyGames";
import SingleNews from "./Pages/SingleNews";

import Development from './Components/development/development'
function App() {
  const [load, setLoad] = useState(false)
  const [development, setDevelopment] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("development")) {
      setDevelopment(true)
    }
    setLoad(true)
  }, [])

  return (
    <div id="wrapper">
      <ThemeProvider theme={theme}>
        {(!development && load) && <Development />}
        {(development && load) && (
          <Router>
            <UserProvider>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/forget" element={<Forget />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/search" element={<SearchResult />} />
                <Route path="/gamereview/:id" element={<GameReview />} />
                <Route path="/news/:id" element={<SingleNews />} />
                <Route
                  path="/OAuthRedirecting/:token"
                  element={<HandleAuthLogin />}
                />
                <Route path="/" element={<ProtectedRoute />}>
                  <Route path="/submitgame" element={<SubmitGame />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/mygames" element={<MyGames />} />
                </Route>
              </Routes>
            </UserProvider>
            <ScrollToTop />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </Router>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
