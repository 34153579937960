import Header from "../Components/Header";
import LoginCom from "../Components/Login/LoginCom";
export default function Login({ }) {
  return (
    <>
      <Header />
      <LoginCom />
    </>
  );
}
