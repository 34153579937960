import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import SingleNews from "../Components/SingleNews/SingleNews";
import Footer from "../Components/Footer";
import { useParams } from "react-router";
import { getOne } from "../api";
export default function GameReview() {
  const { id } = useParams();
  const [game, setGame] = useState({});
  const getGameData = async () => {
    const url = '/news/' + id
    try {
      var res = await getOne(url);
      if (res) {
        setGame(res.data);
      }
    } catch (error) {
      console.log("Failed to News Game Data", error);
    }

  };
  useEffect(() => {
    getGameData();
  }, [id]);

  return (
    <div className="dark-bg">
      <Header />
      <SingleNews game={game} />
      <Footer />
    </div>
  );
}

