export default function Hero({ Herobg, children }) {
  return (
    <div className="hero-container">
      <div className="hero-bg" style={{ backgroundImage: `url(${Herobg})`, backgroundAttachment: 'fixed' }}>

        {/* <div className="hero-overlay"> */}
        <div className="flex" style={{ height: '100%', display: 'flex', background: 'rgba(0,0,0,0.4)' }}>
          <div className="game_content" style={{ margin: 'auto', width: '100%' }}>
            {children}
          </div>
        </div>
      </div>

    </div>
    // </div>
  );
}
