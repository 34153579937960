import React, { useContext, useEffect, useRef, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Avatar from "@mui/material/Avatar";
import {
  Slider,
  Typography,
  Button,
  Modal,
  TextField,
  Grid,
  InputLabel
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { createReview } from "../../api";
import { toast } from "react-toastify";
import UserContext from "../../Context/UserContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import { fileUpload } from "../../api";
import Login from "../../Components/Login/LoginCom";

// Create a custom theme with customized warning color
const theme = createTheme({
  palette: {
    warning: {
      main: "#f0dc04",
    },
  },
});
export default function GameMedia({ game, onAdd }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [gameplay, setGameplay] = useState(10);
  const [difficultyLevel, setDifficultyLevel] = useState(10);
  const [hourlyPlayed, setHourlyPlayed] = useState(10);
  const [story, setStory] = useState(10);
  const [visuals, setVisuals] = useState(10);
  const [sound, setSound] = useState(10);
  const [controls, setControls] = useState(10);
  const [replayability, setReplayability] = useState(10);
  const [originality, setOriginality] = useState(10);
  const [value, setValue] = useState(10);
  const [technicalPerformance, setTechnicalPerformance] = useState(10);
  const [accessibility, setAccessibility] = useState(10);
  const [comment, setComment] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);

  const handleSliderChange = (setter) => (event, newValue) => {
    setter(newValue);
  };



  const handleOpenModal = () => {
    setModalOpen(true);

    // if (user) {

    // } else {
    //   // toast.error("Login to add the review.");
    //   console.log(user)
    //   // setModalOpen(true);
    // }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("gameplay", gameplay);
      formData.append("story", story);
      formData.append("visuals", visuals);
      formData.append("sound", sound);
      formData.append("controls", controls);
      formData.append("replayability", replayability);
      formData.append("originality", originality);
      formData.append("value", value);
      formData.append("technicalPerformance", technicalPerformance);
      formData.append("accessibility", accessibility);
      formData.append("difficultyLevel", difficultyLevel);
      formData.append("hourlyPlayed", hourlyPlayed);
      formData.append("comment", comment);
      if (gameImage) {
        formData.append("attachment", gameImage);
      }
      formData.append("game", game._id);
      formData.append("gameURL", game.gameURL);
      const res = await createReview(formData);
      if (res) {
        toast.success(res.data.msg);
        onAdd();
        setGameplay(10);
        setDifficultyLevel(10);
        setHourlyPlayed(10);
        setStory(10);
        setVisuals(10);
        setSound(10);
        setControls(10);
        setReplayability(10);
        setOriginality(10);
        setValue(10);
        setTechnicalPerformance(10);
        setAccessibility(10);
        setComment("");
        setGameImage("");
        handleCloseModal();
      }
    } catch (error) {
      toast.error("Error while adding review.");

      console.log("Error while adding review.", error);
    } finally {
      setIsLoading(false);
    }
  };


  const [gameImage, setGameImage] = useState()
  const { getRootProps: getRootThubProps, getInputProps: getInputThubProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return; // Do not proceed further
      }
      const { data } = await fileUpload('/media/uploads', file)
      if (data.url) {
        setGameImage(data.url)
      }
    },
  });


  const [outside, setOutside] = useState(false)

  const getLogin = (click) => {
    setModalOpen(click)
  }


  return (
    <>

      {(game && game.createdAt) &&

        <div className="container pt-5 pb-2">
          <div className="row my-3 d-flex align-items-stretch">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <h2 className="my-5 col-sm-6">{game.gameName}</h2>
              <Button onClick={handleOpenModal} className="" variant="contained">
                Add Review
              </Button>
            </div>

            <div className="media_game mb-2 text-muted col-12 d-flex justify-content-start align-items-center text-light">
              <div className="one" style={{ color: '#fff' }}>
                <Avatar
                  alt={game.uploadedBy?.username}
                  src=""
                  className="me-2"
                  style={{
                    width: "35px",
                    height: "35px",
                    boxShadow: "inset 0 0 0 1px white",
                  }}
                />

                <span>{game.uploadedBy?.username}</span>
              </div>
              <div className="two" style={{ color: '#fff' }}>
                <FiberManualRecordIcon
                  className="me-2 ms-4"
                  style={{ fontSize: "10px" }}
                />
                <span>{game.gameGenre}</span>
              </div>
              <div className="three" style={{ color: '#fff' }}>
                <FiberManualRecordIcon
                  className="me-2 ms-4"
                  style={{ fontSize: "10px" }}
                />
                <span>{game.gameFeatures}</span>
              </div>
              <div className="four" style={{ color: '#fff' }}>
                <FiberManualRecordIcon
                  className="me-2 ms-4"
                  style={{ fontSize: "10px" }}
                />
                <span>{new Date(game.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}</span>
              </div>
            </div>




            <ThemeProvider theme={theme}>

              {(!user) && <Modal className="worst_model" open={modalOpen} onClose={handleCloseModal}>
                <Login onClick={getLogin} />
              </Modal>
              }

              {user &&
                <Modal className="worst_model" open={modalOpen} onClose={handleCloseModal}>
                  <div
                    className="light-bg p-4 rounded-4 col-lg-5 col-md-7 col-11"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >


                    <div className="icon" style={{
                      textAlign: 'right'
                    }}>
                      <CloseIcon fontSize={"medium"} onClick={handleCloseModal} />
                    </div>
                    <h3 className="text-center col-12">How's your experience?</h3>
                    <p className="text-center col-12 text-muted">
                      Provide your ratings and general review for the game.
                    </p>
                    <Grid container spacing={2} className="add_slider">
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Game Play</Typography>
                          <p>{gameplay}</p>
                        </div>
                        <Slider
                          value={gameplay}
                          onChange={handleSliderChange(setGameplay)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>


                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Story</Typography>
                          <p>{story}</p>
                        </div>
                        <Slider
                          value={story}
                          onChange={handleSliderChange(setStory)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>

                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Visuals</Typography>
                          <p>{visuals}</p>
                        </div>
                        <Slider
                          value={visuals}
                          onChange={handleSliderChange(setVisuals)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Sound</Typography>
                          <p>{sound}</p>
                        </div>
                        <Slider
                          value={sound}
                          onChange={handleSliderChange(setSound)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Controls</Typography>
                          <p>{controls}</p>
                        </div>
                        <Slider
                          value={controls}
                          onChange={handleSliderChange(setControls)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Replayability</Typography>
                          <p>{replayability}</p>
                        </div>
                        <Slider
                          value={replayability}
                          onChange={handleSliderChange(setReplayability)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>

                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Originality</Typography>
                          <p>{originality}</p>
                        </div>
                        <Slider
                          value={originality}
                          onChange={handleSliderChange(setOriginality)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Value</Typography>
                          <p>{value}</p>
                        </div>
                        <Slider
                          value={value}
                          onChange={handleSliderChange(setValue)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Technical Performance</Typography>
                          <p>{technicalPerformance}</p>
                        </div>
                        <Slider
                          value={technicalPerformance}
                          onChange={handleSliderChange(setTechnicalPerformance)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Accessibility</Typography>
                          <p>{accessibility}</p>
                        </div>
                        <Slider
                          value={accessibility}
                          onChange={handleSliderChange(setAccessibility)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>
                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Difficulty Level</Typography>
                          <p>{difficultyLevel}</p>
                        </div>
                        <Slider
                          value={difficultyLevel}
                          onChange={handleSliderChange(setDifficultyLevel)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>

                      <Grid className="my-0" item xs={6}>
                        <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                          <Typography gutterBottom>Hourly Game Played</Typography>
                          <p>{hourlyPlayed}</p>
                        </div>
                        <Slider
                          value={hourlyPlayed}
                          onChange={handleSliderChange(setHourlyPlayed)}
                          color="warning"
                          defaultValue={10}
                          max={10}
                        />
                      </Grid>

                    </Grid>



                    <InputLabel className="text-white mb-3">
                      Image
                    </InputLabel>
                    <div
                      role="button"
                      style={{
                        borderRadius: 8,
                        border: "2px dashed gray",
                        textAlign: "center",
                      }}
                    >
                      <div {...getRootThubProps()} style={{ display: "inline-block", height: '150px', width: '100%', overflow: 'unset' }}>
                        <input id="thubnail" {...getInputThubProps()} />
                        {gameImage ? (
                          // Display image preview
                          <img
                            src={gameImage}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: '150px',
                            }}
                          />
                        )
                          : gameImage ? (
                            // Display image preview
                            <img
                              src={gameImage}
                              alt="Preview"
                              style={{
                                maxWidth: "100%",
                                height: "auto",
                                maxHeight: '150px',
                              }}
                            />
                          )
                            : (<>
                              <FileUploadIcon className="mt-5 mb-2" />
                              <p>
                                Drag and drop some files here, or click to select files
                              </p>
                            </>)}
                      </div>
                    </div>
                    <p className=" mt-1">Max:10MB</p>

                    <TextField
                      className="my-3"
                      fullWidth
                      label="Add Review"
                      variant="outlined"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      InputProps={{
                        style: {
                          color: "white", // Change text color to white
                          borderColor: "white", // Change border color to white
                        },
                      }}
                      InputLabelProps={{
                        className: "text-white", // Change label text color to white
                      }}
                      multiline
                      rows={4}
                    />
                    <Button
                      variant="contained"
                      fullWidth
                      className="py-2"
                      color="primary"
                      disabled={isLoading}
                      onClick={() => handleSubmit()}
                    >
                      {isLoading ? (
                        <CircularProgress sx={{ color: "white" }} size={27} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Modal>
              }
            </ThemeProvider>
          </div>
        </div>
      }
    </>
  );
}
