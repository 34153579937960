export const getToday = (fullDate) => {
  const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
  const date = new Date(fullDate)
  return months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
}


export const substring = (text, total) => {
  if (text && text.length > total) {
    return text.substring(0, total) + "..."
  }
  if (text && text.length <= total) {
    return text
  }
}

export const math = (val) => {
  return Math.round(val * 100) / 100
}
