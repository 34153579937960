import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Chip,
  FormControl,
  Select,
  MenuItem,
  Button,
  Modal,
  Grid,
  LinearProgress,
  Pagination
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from '@mui/icons-material/Close';
import { getOne, getAll } from "../../api";
import { toast } from "react-toastify";
import { math } from "../../helpers/functions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    warning: {
      main: "#f0dc04",
    },
  },
});

const perPageReview = 10

const UserReview = ({ reviews }) => {
  const [data, setData] = useState([])
  const [oldData, setOldData] = useState([])
  const [oldCurrentPage, setOldCurrentPage] = useState(1);
  const [oldTotalPages, setOldTotalPages] = useState(0);
  const [oldTotalReviews, setOldTotalReviews] = useState(0);

  const [game, setGame] = useState([])

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);


  useEffect(() => {
    setData(reviews.reviews)
    setGame(reviews.game)
    return () => {
      setTotalReviews(reviews.total);
      setTotalPages(Math.ceil(reviews.total / perPageReview));

      setOldData(reviews.reviews)
      setOldTotalPages(Math.ceil(reviews.total / perPageReview));
      setOldTotalReviews(reviews.total);
    }

  }, [reviews])

  const handlePageChange = async (event, newPage) => {
    setCurrentPage(newPage);
    try {
      const { data } = await getOne(`/reviews/game/${game.gameURL}?document=true&page=${newPage}`)

      if (data.success) setData(data.data)

    } catch (error) {
      toast.error(error.message)
    }
  };

  const [version, setVersion] = useState("Filter By Version");

  const versionHandler = async (e) => {
    const value = e.target.value
    setVersion(value)
    if (value !== "Filter By Version") {
      try {
        const { data } = await getAll('/reviews/game/filter/' + game.gameURL, {
          version: value,
          type: "version",
          user: true
        })
        if (data.success) {
          setData(data.data)
          setTotalPages(Math.ceil(data.total / perPageReview));
          setTotalReviews(data.total);
          setCurrentPage(1);

        }

      } catch (error) {
        toast.error(error.message)
      }
    } else {
      setData(oldData)
      setCurrentPage(oldCurrentPage)
      setTotalReviews(oldTotalReviews)
      setTotalPages(oldTotalPages)
    }
  }


  const [modalOpen, setModalOpen] = useState(false);

  const [ratings, setRatings] = useState([]);

  const handleOpenModal = (review) => {
    setModalOpen(true);
    let gameplay = math(review.gameplay * 10)
    let story = math(review.story * 10)
    let visuals = math(review.visuals * 10)
    let sound = math(review.sound * 10)
    let controls = math(review.controls * 10)
    let replayability = math(review.replayability * 10)
    let originality = math(review.originality * 10)
    let value = math(review.value * 10)
    let technicalPerformance = math(review.technicalPerformance * 10)
    let accessibility = math(review.accessibility * 10)

    setRatings(
      [
        { item: "Game Play", rating: gameplay },
        { item: "Story", rating: story },
        { item: "Visuals", rating: visuals },
        { item: "Sounds", rating: sound },
        { item: "Controls", rating: controls },
        {
          item: "Replayability",
          rating: replayability,
        },
        {
          item: "Originality",
          rating: originality,
        },
        { item: "Value", rating: value },
        {
          item: "Technical Performance",
          rating: technicalPerformance,
        },
        {
          item: "Accessibility",
          rating: accessibility,
        }
      ]
    )
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };



  return (<>

    <div className="container">

      <div className="row">
        <div className="col">
          <h2>Featured Reviews</h2>
        </div>

        {game.version &&
          <div className="col" style={{
            textAlign: 'right',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}>
            <FormControl className="custom_select" size="small">
              <Select
                value={version}
                onChange={versionHandler}
                label="Filter By Version"
                className="select_box_main"
              >
                <MenuItem value="Filter By Version" key="Filter By Version">Filter By Version</MenuItem>
                {(game && game.allVersion) && game.allVersion.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
        }
      </div>

      {data.length > 0 && data.map((review, index) => (
        <Card className="saifjames dark-bg text-white" key={index} sx={{ mb: 2 }}>
          <div className="row py-3 " style={{ borderBottom: "1px solid gray" }}>
            <CardHeader
              className="col-lg-3 mb-auto col-md-4 col-12"
              avatar={
                <Avatar src={review.user?.profile} alt={review.user?.profile} />
              }
              title={review.user?.username}
              subheader={new Date(review.createdAt).toLocaleString()}
              subheaderTypographyProps={{
                variant: "subtitle2",
                color: "white",
              }} // Customize the typography style
            />

            <CardContent className="col-lg-9 col-md-8 col-12">
              <Typography variant="body1" sx={{ mb: 1 }} style={{ color: 'gold' }}>
                {review.rating} out of 10
              </Typography>
              <Typography variant="body2">{review.review}</Typography>
              {review?.attachment ? (
                <Stack direction="row" className="my-2" spacing={1}>
                  <a
                    target="_blank"
                    href={review.attachment}
                  >
                    {" "}
                    <Chip
                      role="button"
                      icon={<OpenInNewIcon className="text-white" />}
                      label="Open Attachment"
                      variant="outlined"
                      className="text-white"
                    />
                  </a>
                </Stack>
              ) : (
                ""
              )}

              <Button onClick={() => handleOpenModal(review)} style={{ color: '#fff', border: '2px solid gold', marginTop: '8px' }}>
                View Review
              </Button>

            </CardContent>
          </div>
        </Card>
      ))}

      {totalPages > 0 &&
        <div className="mt-3 d-flex justify-content-center py-1 pagination custom_pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"

          />
        </div>
      }

      <ThemeProvider theme={theme}>
        <Modal open={modalOpen} onClose={handleCloseModal} className="dynamic_single_model">

          <div
            className="light-bg p-4 rounded-4 col-lg-5 col-md-6 col-10"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",

              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div className="close" onClick={handleCloseModal}>
              <CloseIcon fontSize={"medium"} style={{ cursor: 'pointer' }} />
            </div>
            <h5 className="text-muted">Ratings</h5>
            {/* <p className="text-muted">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. dolor
                temporibus eveniet.
              </p> */}
            <Grid container spacing={2}>
              {ratings.map((rating, index) => (
                <>
                  <Grid className="my-2" item lg={6} md={6} sm={6} xs={12} key={index}>
                    <div className="d-flex justify-content-between">
                      <Typography gutterBottom>{rating.item}</Typography>
                      <Typography variant="body2" className="text-muted">
                        {rating.rating}%
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      color="warning"
                      value={rating.rating}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
        </Modal>
      </ThemeProvider>


    </div>

  </>
  );
};

UserReview.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      userAvatar: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      comment: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UserReview;
