import React, { useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { randomGame } from "../../api";
export default function Hero() {
  const [game, setGame] = useState([]);

  useEffect(() => {
    const getGameData = async () => {
      try {
        var res = await randomGame();
        if (res) {
          setGame(res.data.randomGame[0]);
        }
      } catch (error) {
        console.log("Failed to get Game Data", error);
      }
    };

    getGameData();
  }, []);
  return (
    <>
      {
        game.createdAt && (
          <div className="hero-container">
            <div
              className="hero-bg"
              style={{
                backgroundImage: `url(${game.gameImage
                  })`,
                backgroundAttachment: 'fixed'
              }}
            />
            <div className="hero-overlay"></div>
            <div className="hero-content container">
              <div className="row d-flex justify-content-start">
                <div className="col-lg-12">
                  <div className="hero-content-wrapper">
                    <h1>{game?.gameName}</h1>
                    <div className="text-muted col-12 d-flex justify-content-start flex-wrap align-items-center hero_contents_main">
                      <StarIcon style={{ color: "yellow", fontSize: "15px", marginTop: '-1px' }} />{" "}
                      {game.rating}
                      <FiberManualRecordIcon
                        className="me-2 ms-4"
                        style={{ fontSize: "10px" }}
                      />{" "}
                      {game?.gameGenre}
                      <FiberManualRecordIcon
                        className="me-2 ms-4"
                        style={{ fontSize: "10px" }}
                      />{" "}
                      {game?.gameFeatures}
                      <FiberManualRecordIcon
                        className="me-2 ms-4"
                        style={{ fontSize: "10px" }}
                      />{" "}
                      {new Date(game?.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                      <br />
                      <p className="my-2 w-100">
                        {game?.gameAbout
                          ?.split(" ") // Split the text into words
                          .slice(0, 50) // Select the first 50 words
                          .join(" ")}{" "}
                        {/* Join the selected words back into a string */}
                      </p>
                      <br />
                      <Link to={"/gamereview/" + game.gameURL}>
                        {" "}
                        <Button variant="contained" className="py-2 my-2">
                          See Reviews
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </>
  );
}
