import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Hero from "../Components/GameReviews/Hero";
import GameMedia from "../Components/GameReviews/GameMedia";
import AboutGame from "../Components/GameReviews/AboutGame";
import FeaturedReviews from "../Components/GameReviews/FeaturedReviews";
import Footer from "../Components/Footer";
import { useParams } from "react-router";
import { getGameById, addGameView, getReviewsByGameId } from "../api";
export default function GameReview() {
  const { id } = useParams();
  const [game, setGame] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [total, setTotal] = useState(0);
  const getGameData = async () => {
    try {
      var res = await getGameById(id);
      if (res) {
        setGame(res.data);
      }
    } catch (error) {
      console.log("Failed to get Game Data", error);
    }
    try {
      var res = await getReviewsByGameId(id);
      if (res) {
        setReviews(res.data.data);
        setTotal(res.data.total);
      }
    } catch (error) {
      console.log("Failed to get Reviews Data", error);
    }
  };
  const plusGameView = async () => {
    try {
      var res = await addGameView(id);
    } catch (error) {
      console.log("Failed to add Game Views", error);
    }
  };

  useEffect(() => {
    getGameData();
    plusGameView();
  }, [id]);
  function onAdd() {
    getGameData();
    plusGameView();
  }
  return (
    <div className="dark-bg">
      <Header />
      <Hero Herobg={game?.gameImage}>
        <GameMedia onAdd={() => onAdd()} game={game} />
      </Hero>

      <AboutGame gamedata={game} ratting={game.rating} />
      <FeaturedReviews reviews={{ reviews: reviews, game: game, total: total }} />
      <Footer />
    </div>
  );
}

