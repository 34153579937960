import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import { Link, useSearchParams, } from "react-router-dom";

import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import UserContext from "../Context/UserContext";
import { updateOne } from "../api";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import bg from "../Assets/auth-bg.png";

export default function Login() {
  const { user, setUserAndToken } = useContext(UserContext);
  const navigate = useNavigate();
  const [query] = useSearchParams()
  const [isProcessing, setisProcessing] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const [form, setForm] = useState(true)
  const [found, setFound] = useState(false)

  const formHandler = async (e) => {
    e.preventDefault()
    const token = query.get('token')

    if (password && token) {
      setisProcessing(true)
      const { data } = await updateOne('/users/reset', { password: password, token: token })
      if (data.success) {
        setForm(false)
        setisProcessing(false)
      } else if (data.notFound) {
        setForm(false)
        setFound(true)
        setisProcessing(false)
      } else {
        toast(data.message)
        setForm(true)
        setisProcessing(false)
      }
    }
  };
  return (
    !user && (
      <div
        className="dark-bg d-flex justify-content-center align-items-center w-100"
        style={{
          minHeight: "100vh",
          position: "relative",
          background: `url(${bg})`,
          backgroundSize: "100% auto", // Set the background size
          backgroundRepeat: "no-repeat", // Prevent image repetition
        }}
      >
        {/* Add the overlay pseudo-element */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black overlay
          }}
        />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="align-items-center rounded py-5 col-lg-4 col-md-5 col-sm-10">
          <Card
            className="p-4 rounded-4 w-100"
            style={{ backgroundColor: "rgba(17, 18, 24, 0.9)" }}
          >
            {form &&
              <form className="reset_form" onSubmit={formHandler}>

                <div className="p-1 text-center">
                  <h3 className="text-white w-100" style={{ opacity: 0.9 }}>
                    Update Password
                  </h3>
                  <p className="text-muted w-100" style={{ opacity: 0.9 }}>
                    Enter your password{" "}
                  </p>
                </div>

                <TextField
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 15,
                    marginBottom: 20,
                  }}
                  fullWidth
                  value={password}
                  id="outlined-basic"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  onChange={handlePasswordChange}
                  InputProps={{
                    startAdornment: (
                      <IconButton style={{ color: "white" }}>
                        <LockIcon />
                      </IconButton>
                    ),

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ color: "white" }}
                          onClick={toggleShowPassword}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                />
                <Button
                  fullWidth
                  // style={{ backgroundColor: "#7b9858", color: "#fff" }}
                  type="submit"
                  className="my-3 rounded-3 py-3"
                  variant="contained"
                  size="large">
                  {isProcessing ? (
                    <CircularProgress sx={{ color: "white" }} size={27} />
                  ) : (
                    "Update Password"
                  )}
                </Button>


                <Link className="text-light" to="/">
                  <Button
                    fullWidth
                    style={{ backgroundColor: "#000", color: "#fff", border: '2px solid #fff' }}
                    className="my-3 rounded-3 py-3 "
                    variant="contained"
                    size="large">
                    Go back to Homepage
                  </Button>
                </Link>
              </form>
            }
            {found &&
              <div className="">
                <p className="alert alert-success">Invalid ruquest</p>
              </div>
            }

            {(!form && !found) && <div className="">
              <p className="alert alert-success">Your password has been updated. <Link to={"/login"}>Login</Link></p>
            </div>
            }

          </Card>
        </div>
      </div>
    )

  );
}
