import React, { useState, useCallback, useContext } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControlLabel,

} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useDropzone } from "react-dropzone";
import { createGame, fileUpload } from "../api";
import AddIcon from "@mui/icons-material/Add";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UserContext from "../Context/UserContext";
import { toast } from "react-toastify";
import Header from "../Components/Header";
import LinkIcon from "@mui/icons-material/Link";

const SubmitGame = () => {
  const [gameName, setGameName] = useState("");
  const [downloadLink, setdownloadLink] = useState("");
  const [gameGenre, setGameGenre] = useState("");
  const [gameFeatures, setGameFeatures] = useState("");
  const [gameImage, setGameImage] = useState("");
  const [gameAbout, setGameAbout] = useState("");
  const [is18Plus, setIs18Plus] = useState(false);
  const [publisher, setPublisher] = useState("");
  const [developer, setDeveloper] = useState("");
  const [preview, setPreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(UserContext);

  const { getRootProps: getRootThubProps, getInputProps: getInputThubProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      if (file && file.size > maxSize) {
        alert("File size exceeds the maximum limit of 10MB");
        return; // Do not proceed further
      }
      const { data } = await fileUpload('/media/uploads', file)
      if (data.url) {
        setGameImage(data.url)
        setPreview(URL.createObjectURL(file));
      }
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [platform, setPlatform] = useState([])
  const platformHandler = (event) => {
    const {
      target: { value },
    } = event;

    setPlatform(value);
  }

  const handleSubmit = async () => {
    if (!gameName || !gameGenre || !gameFeatures || !gameAbout || !gameImage || platform.length === 0 || !publisher) {
      toast.error("Kindly fill all inputs");
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData(); // Create a new FormData object
      formData.append("gameName", gameName);
      formData.append("gameGenre", gameGenre);
      formData.append("gameFeatures", gameFeatures);
      formData.append("platform", JSON.stringify(platform));
      formData.append("publisher", publisher);
      formData.append("developer", developer);
      formData.append("gameAbout", gameAbout);
      formData.append("is18Plus", is18Plus);
      formData.append("link", downloadLink);
      formData.append("gameImage", gameImage); // Append the file data to the FormData object
      formData.append("uploadedBy", user.id); // Assuming you have the user ID in user.id
      const response = await createGame(formData);
      if (response.status == 201) {
        // Clear the form fields
        setGameName("");
        setGameGenre("");
        setGameFeatures("");
        setGameImage("");
        setGameAbout("");
        setIs18Plus(false);
        setPreview("")
        window.location.href = '/mygames'
        toast.success(response.data.msg || "Game added successfully");
      } else {
        toast.error(response.data.msg || "Failed to add game");
      }
    } catch (error) {
      console.error("Error adding game:", error);
      toast.error(error.response.data.msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid py-5 dark-bg">
        <div className="container  pb-5 submit_game_form">
          <h2 className="text-center text-white" id="form-dialog-title">
            Submit Game For Review
          </h2>
          <DialogContent className=" text-white">
            <div className="row d-flex">
              <div className="col-md-6">
                <TextField
                  className="my-3 border_input"
                  fullWidth
                  label="Game Name"
                  variant="outlined"
                  value={gameName}
                  onChange={(e) => setGameName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <IconButton style={{ color: "white" }}>
                        <SportsEsportsIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                  required
                />

                <FormControl fullWidth variant="outlined" className="my-3">
                  <InputLabel className="text-white">Game Genre</InputLabel>
                  <Select
                    value={gameGenre}
                    onChange={(e) => setGameGenre(e.target.value)}
                    label="Game Genre"
                    className="text-white border_input"
                  >
                    {/* Replace the options with your actual game genres */}
                    <MenuItem value="Action">Action</MenuItem>
                    <MenuItem value="Adventure">Adventure</MenuItem>
                    <MenuItem value="RPG">RPG</MenuItem>
                    {/* Add more genres as needed */}
                  </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" className="my-3">
                  <InputLabel className="text-white">Game Features</InputLabel>
                  <Select
                    value={gameFeatures}
                    onChange={(e) => setGameFeatures(e.target.value)}
                    label="Game Features"
                    className="text-white border_input"
                  >
                    {/* Replace the options with your actual game features */}
                    <MenuItem value="Multiplayer">Multiplayer</MenuItem>
                    <MenuItem value="Singleplayer">Singleplayer</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                    {/* Add more features as needed */}
                  </Select>
                </FormControl>


                <FormControl fullWidth variant="outlined" className="my-3">
                  <InputLabel id="demo-multiple-checkbox-label" className="text-white">Platform</InputLabel>
                  <Select
                    label="Platform"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    className="text-white border_input"
                    multiple
                    input={<OutlinedInput label="Tag" />}
                    onChange={platformHandler}
                    renderValue={(selected) => selected.join(', ')}
                    value={platform}
                    MenuProps={MenuProps}

                  >

                    {
                      ['PC', 'Console', 'Mobile'].map(item => {
                        return (
                          <MenuItem key={item} value={item}>
                            <Checkbox checked={platform.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                          </MenuItem>
                        )
                      })
                    }

                    {/* Add more features as needed */}
                  </Select>
                </FormControl>



                <TextField
                  className="my-3 border_input"
                  fullWidth
                  label="Developer"
                  variant="outlined"
                  value={developer}
                  onChange={(e) => setDeveloper(e.target.value)}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                  required
                />


              </div>

              <div className="col-md-6">
                <InputLabel className="text-white mb-3">
                  Game Image or Video
                </InputLabel>
                <div
                  role="button"
                  style={{
                    borderRadius: 8,
                    border: "2px dashed gray",
                    textAlign: "center",
                  }}
                >
                  <div {...getRootThubProps()} style={{ display: "inline-block", height: '190px', overflow: 'unset' }}>
                    <input id="thubnail" {...getInputThubProps()} />
                    {gameImage ? (
                      // Display image preview
                      <img
                        src={gameImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: 200,
                        }}
                      />
                    )
                      : gameImage ? (
                        // Display image preview
                        <img
                          src={gameImage}
                          alt="Preview"
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: 200,
                          }}
                        />
                      )
                        : (<>
                          <FileUploadIcon className="mt-5 mb-2" />
                          <p>
                            Drag 'n' drop some files here, or click to select files
                          </p>
                        </>)}
                  </div>
                </div>
                <p className=" mt-1">Max:10MB</p>
                <TextField
                  className="my-3 border_input"
                  fullWidth
                  label="Download Link"
                  variant="outlined"
                  value={downloadLink}
                  onChange={(e) => setdownloadLink(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <IconButton style={{ color: "white" }}>
                        <LinkIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                  required
                />

                <TextField
                  className="my-3 border_input"
                  fullWidth
                  label="Publisher"
                  variant="outlined"
                  value={publisher}
                  onChange={(e) => setPublisher(e.target.value)}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                  required
                />
              </div>


            </div>


            <TextField
              className="my-3 border_input"
              fullWidth
              label="Game About"
              variant="outlined"
              value={gameAbout}
              onChange={(e) => setGameAbout(e.target.value)}
              InputLabelProps={{
                className: "text-white",
              }}
              multiline
              rows={4}
            />

            <FormControlLabel
              control={
                <Checkbox
                  className="light-bg checkbox_color"
                  checked={is18Plus}
                  onChange={(e) => setIs18Plus(e.target.checked)}
                  color="primary"
                />
              }
              label="Is this Game 18+"
              className="text-white"
            />
          </DialogContent>
          <DialogActions className=" text-white">
            <Button
              onClick={handleSubmit}
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              disabled={isLoading}
              fullWidth
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "white" }} size={27} />
              ) : (
                " Submit Game"
              )}
            </Button>
          </DialogActions>
        </div>
      </div>
    </>
  );
};

export default SubmitGame;
