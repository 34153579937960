import React from "react";
import Header from "../Components/Header";
import Hero from "../Components/HomePage/Hero";
import TrendingToday from "../Components/HomePage/TrendingToday";
import TopGames from "../Components/HomePage/TopGames";
import Guide from "../Components/HomePage/Guide";
import TopNews from "../Components/HomePage/TopNews";
import Footer from "../Components/Footer";

export default function HomePage() {

  return (
    <div className="dark-bg">
      <Header />
      <Hero />
      <TrendingToday />
      <TopGames />
      <Guide />
      <TopNews />
      <Footer />
    </div>
  );
}
