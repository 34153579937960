import { useState, useContext, useRef, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import logo from "./logo.png";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../Context/UserContext";
import { Avatar } from "@mui/material";
import { searchGame } from "../api";

import './Header.css'
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Header({ }) {
  const { user, handleLogout } = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchResult, setSearchResult] = useState([])
  const [timer, setTimer] = useState(null);
  const resultRef = useRef(null);

  useEffect(() => {
    const notificationClick = (e) => {
      if (resultRef && resultRef.current && !resultRef.current.contains(e.target)) {
        setSearchResult([])
        setSearchLoad(false)
      }
    };
    window.addEventListener("click", notificationClick);
    return () => window.removeEventListener("click", notificationClick);
  }, [])
  useEffect(() => {
    function blockByPath() {
      const excludePath = ["/signup", "/login", "/forget", "/logout"]
      let blockPath = false
      excludePath.map((item) => {
        if (item === window.location.pathname) {
          blockPath = true
        }
      })
      return blockPath
    }

    if (!blockByPath()) {
      localStorage.setItem("page", window.location.href)
    }
  }, [])

  const [searchLoad, setSearchLoad] = useState(false)
  const changeDelay = e => {
    const keyword = e.target.value
    setSearchKeyword(keyword);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    if (keyword && keyword.trim()) {
      setTimer(
        setTimeout(async () => {
          const { data } = await searchGame({
            page: 1,
            perPage: 15,
            keyword: keyword,
            filter: select,
          });
          setSearchResult(data.games)
          setSearchLoad(true)
        }, 1000)
      );
    } else {
      setSearchResult([])
      setSearchLoad(false)
    }

  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const handleSearch = () => {
    if (searchKeyword) {
      navigate(`/search?search=${searchKeyword}&filter=${select}`);
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      style={{ top: '50px', }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      <Link
        to="/mygames"
        style={{
          color: "inherit",
          textDecoration: "none",
        }}
      >
        <MenuItem>My Games</MenuItem>
      </Link>
      <Link
        to="/settings"
        style={{
          color: "inherit",
          textDecoration: "none",
        }}
      >
        <MenuItem>Settings</MenuItem>
      </Link>
      <Link
        to="/contact"
        style={{
          color: "inherit",
          textDecoration: "none",
        }}
      >
        <MenuItem>Contact</MenuItem>
      </Link>
      {(user && user.type === 'Admin') &&
        <a
          href="/admin_games"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <MenuItem>Admin</MenuItem>
        </a>
      }
      <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      {user ? [
        <Link key={0} to="/submitgame"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}>
          <MenuItem>Submit Game</MenuItem>
        </Link>,

        <Link key={1}
          to="/mygames"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <MenuItem>My Games</MenuItem>
        </Link>,

        <Link
          key={2}
          to="/settings"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <MenuItem>Settings</MenuItem>
        </Link>,
        <Link
          key={3}
          to="/contact"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <MenuItem>Contact</MenuItem>
        </Link>
        ,
        user.type === 'Admin' && <a
          key={3}
          href="/admin_games"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <MenuItem>Admin</MenuItem>
        </a>,
        <MenuItem key={4} onClick={() => handleLogout()}>Logout</MenuItem>
      ]
        :
        [
          <MenuItem key={7}>
            <Link to="/login"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}>Sing In</Link>
          </MenuItem>,

          <Link
            key={8}
            to="/contact"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <MenuItem>Contact</MenuItem>
          </Link>
        ]

      }

    </Menu>
  );

  // const [select, setSelect] = React.useState('Review Scores')
  const [select, setSelect] = useState('Filter')
  const filterChnage = (e) => {
    setSelect(e.target.value)
  }

  const dynamicTitle = (content) => {
    if (content) {
      const length = 30
      if (content.length > length) {
        return content.substring(0, length) + '...'
      } else {
        return content
      }
    }
    return;
  }
  return (
    <Box sx={{ flexGrow: 1 }} className="player_header">
      <AppBar position="static" className="dark-bg">
        <Toolbar className="tollbar_main">
          <div className="left">
            <Typography className="logo_main"
              variant="h6"
              noWrap
              component="div"

            >
              <Link to="/">
                {" "}
                <img src={logo} width="200px" alt="Logo" />
              </Link>
            </Typography>
          </div>

          <div className="middle">
            <div className="search_box">
              <Search fullWidth className="custom_search" sx={{ flexGrow: 2 }}>
                <SearchIconWrapper>
                  <SearchIcon onClick={handleSearch} /> {/* Add onClick event */}
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  fullWidth
                  inputProps={{
                    "aria-label": "search",
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        handleSearch(); // Trigger search when Enter key is pressed
                      }
                    },
                    onChange: changeDelay,
                    value: searchKeyword,
                  }}
                />
                {(searchResult.length > 0 && searchLoad) &&
                  <div className="search_result" ref={resultRef}>
                    {searchResult.map((item) => {
                      return (
                        <Link
                          to={"/gamereview/" + item.gameURL}
                          className="search_result_link"
                        >
                          <div className="result_item">
                            {item.gameImage &&
                              <div className="result_item_left">
                                <img src={process.env.REACT_APP_BACKEND + item.gameImage} alt="" />
                              </div>
                            }
                            <div className="result_item_right">
                              <p>{dynamicTitle(item.gameName)}</p>
                              <p className="rating">rating: {item.rating}</p>
                            </div>
                          </div>
                        </Link>
                      )
                    })
                    }
                  </div>
                }
                {(searchResult.length === 0 && searchLoad) &&
                  <div className="search_result" ref={resultRef}>
                    <div className="result_item">
                      <p className="text-light" style={{ fontWeight: 'bold' }}>0 Games Found</p>
                    </div>
                  </div>
                }
              </Search>

            </div>

            <div className="search_form">
              <FormControl className="custom_select" sx={{ marginRight: '10px' }} size="small">
                <Select
                  labelId="demo-simple-select-label"
                  className="select_box_main"
                  value={select}
                  label={select}
                  onChange={filterChnage}
                >

                  <MenuItem disabled value={"Filter"}>Filter</MenuItem>
                  <MenuItem value={"Console"}>Console</MenuItem>
                  <MenuItem value={"Release Date"}>Release Date</MenuItem>
                  <MenuItem value={"Review Scores"}>Review Scores</MenuItem>
                  <MenuItem value={"Genre"}>Genre</MenuItem>

                </Select>
              </FormControl>
            </div>

            <div className="search_btn">
              <Button variant="contained" onClick={() => handleSearch()}>
                Search
              </Button>
            </div>

          </div>

          <div className="right">
            <Box sx={{ display: { xs: "none", md: "flex" } }}>

              {user ? (
                <>
                  <IconButton size="large" color="inherit">
                    <Badge color="error">
                      <Link to="/submitgame">
                        {" "}
                        <Button
                          className="text-white border-white"
                          variant="outlined"
                        >
                          Submit Game
                        </Button>
                      </Link>
                    </Badge>
                  </IconButton>

                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        boxShadow: "inset 0 0 0 1px white",
                        marginRight: 1, // Adjust the border width as needed
                      }}
                      src={user?.profile}
                    />
                    <Typography variant="body1">{user?.name}</Typography>
                    <KeyboardArrowDownIcon />
                  </IconButton>

                </>)
                : (
                  <>
                    <IconButton size="large" color="inherit">
                      <Badge color="error">
                        <Link to="/login">
                          {" "}
                          <Button
                            className="text-white border-white"
                            variant="outlined"
                          >
                            Sign In
                          </Button>
                        </Link>
                      </Badge>
                    </IconButton>
                    <IconButton size="large" color="inherit">
                      <Badge color="error">
                        <Link to="/contact">
                          {" "}
                          <Button
                            className="text-white border-white"
                            variant="outlined"
                          >
                            Contact
                          </Button>
                        </Link>
                      </Badge>
                    </IconButton>
                  </>
                )}

            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

    </Box>
  );
}
