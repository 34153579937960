import React, { createContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      const storedToken = localStorage.getItem("playercritic_user_Token");
      if (storedToken) {
        try {
          const decoded = jwtDecode(storedToken);
          const currentTime = Date.now() / 1000;
          if (decoded.exp < currentTime) {
            handleLogout();
          } else {
            setUser(decoded.user);
          }
        } catch (error) {
          // Handle token decoding errors
          console.error("Invalid token:", error);
          handleLogout();
        }
      }
      setIsCheckingUser(false);
    };
    checkUser();
  }, []);

  const setUserAndToken = (userData, tokenData) => {
    localStorage.setItem("playercritic_user_Token", JSON.stringify(tokenData));
    setUser(userData);
  };

  const handleLogout = () => {
    setUserAndToken(null, null);
    localStorage.removeItem("playercritic_user_Token");
    localStorage.removeItem("playercritic_user_User");
    navigate("/login");
  };

  const updateUserHandle = (updatedUser) => {
    setUser(updatedUser);
  };

  const contextValue = {
    user,
    setUserAndToken,
    updateUserHandle,
    handleLogout,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {!isCheckingUser && children}
    </UserContext.Provider>
  );
};

export default UserContext;
