import ReactHtmlParser from "react-html-parser"
import { getToday } from "../../helpers/functions";
const SingleNewsComponent = ({ game }) => {
  return (
    game && (

      <div className="container">
        <div className="col-lg-12 mt-5">
          {game.cover &&
            <div
              className="news_background_image"
              style={{
                backgroundImage: `url(${game.cover
                  })`,
              }}
            />
          }

          <div className="col-lg-12">
            <div className="mt-3 mb-3">
              <h1>{game?.title}</h1>
            </div>
            {(!game.cover && game.image) &&
              <div className="news_img">
                <img src={game.image} alt="" />
              </div>
            }
            <div className="mt-2 news_description">
              {ReactHtmlParser(game.description)}
            </div>

            <div className="my-2 uploadedBy">
              <span className="feature-text text-muted">
                {getToday(game.createdAt)}
              </span>
              {game.uploadedBy &&
                <span className="feature-text text-muted"> by {game.uploadedBy.username}</span>
              }
            </div>

          </div>
        </div>
      </div>
    )
  );
}
export default SingleNewsComponent