import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import CircularProgress from "@mui/material/CircularProgress";
import UserContext from "../Context/UserContext";
import { createOne, updateOne } from "../api";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import bg from "../Assets/auth-bg.png";
import Header from "../Components/Header";

export default function Login() {
  const { user, setUserAndToken } = useContext(UserContext);
  const [email, setemail] = useState("");
  const navigate = useNavigate();
  const [isProcessing, setisProcessing] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const [form, setForm] = useState(true)
  const [found, setFound] = useState(false)
  const formHandler = async (e) => {
    e.preventDefault()
    if (email) {
      setisProcessing(true)
      const { data } = await createOne('/users/forget', { email: email })
      if (data.success) {
        setForm(false)
        setisProcessing(false)
      } else if (data.notFound) {
        setFound(true)
        setisProcessing(false)
      } else {
        toast(data.message)
        setForm(true)
        setisProcessing(false)
      }
    }
  };
  return (
    <>
      <Header />
      {
        !user && (
          <div
            className="dark-bg d-flex justify-content-center align-items-center w-100"
            style={{
              minHeight: "100vh",
              position: "relative",
              background: `url(${bg})`,
              backgroundSize: "100% auto", // Set the background size
              backgroundRepeat: "no-repeat", // Prevent image repetition
            }}
          >
            {/* Add the overlay pseudo-element */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black overlay
              }}
            />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <div className="align-items-center rounded py-5 col-lg-4 col-md-5 col-sm-10">
              <Card
                className="p-4 rounded-4 w-100"
                style={{ backgroundColor: "rgba(17, 18, 24, 0.9)" }}
              >
                {form &&
                  <form className="reset_form" onSubmit={formHandler}>

                    <div className="p-1 text-center">
                      <h3 className="text-white w-100" style={{ opacity: 0.9 }}>
                        Forget Password
                      </h3>
                      <p className="text-muted w-100" style={{ opacity: 0.9 }}>
                        Enter your email{" "}
                      </p>
                    </div>

                    <TextField
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 15,
                        marginTop: 20,
                      }}
                      className="my-4"
                      fullWidth
                      id="outlined-basic"
                      label="Email"
                      value={email}
                      variant="outlined"
                      onChange={(e) => setemail(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <IconButton style={{ color: "white" }}>
                            <EmailIcon />
                          </IconButton>
                        ),
                      }}
                      InputLabelProps={{
                        className: "text-white",
                      }}
                    />
                    {found &&
                      <div className="">
                        <p className="alert alert-success">User is not found</p>
                      </div>
                    }

                    <Button
                      fullWidth
                      // style={{ backgroundColor: "#7b9858", color: "#fff" }}
                      type="submit"
                      className="my-3 rounded-3 py-3"
                      variant="contained"
                      size="large">
                      {isProcessing ? (
                        <CircularProgress sx={{ color: "white" }} size={27} />
                      ) : (
                        "Reset Password"
                      )}
                    </Button>

                    <Link className="text-light" to="/login">
                      <Button
                        fullWidth
                        style={{ backgroundColor: "#000", color: "#fff", border: '2px solid red' }}
                        className="my-3 rounded-3 py-3 "
                        variant="contained"
                        size="large">
                        Go back to Login
                      </Button>
                    </Link>
                    <Link className="text-light" to="/">
                      <Button
                        fullWidth
                        style={{ backgroundColor: "#000", color: "#fff", border: '2px solid #fff' }}
                        className="my-3 rounded-3 py-3 "
                        variant="contained"
                        size="large">
                        Go back to Homepage
                      </Button>
                    </Link>
                  </form>
                }
                {!form && <div className="">
                  <p className="alert alert-success">Reset password link has been sent to your email.</p>
                </div>
                }

              </Card>
            </div>
          </div>
        )
      }
    </>
  );
}
