import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import AppleIcon from "@mui/icons-material/Apple";

export default function ThirdPartyLogin() {
  return (
    <div>
      {" "}
      <a href={process.env.REACT_APP_BACKEND + "/auth/google"}>
        <Button
          fullWidth
          // style={{ backgroundColor: "#7b9858", color: "#fff" }}
          className="my-3 rounded-3 py-3 light-bg"
          variant="contained"
          size="large"

        // onClick={() => doLogin()}
        >
          <GoogleIcon className="me-3" /> Continue With Google
        </Button>
      </a>
      <a href={process.env.REACT_APP_BACKEND + "/auth/apple"}>
        <Button
          fullWidth
          // style={{ backgroundColor: "#7b9858", color: "#fff" }}
          className="my-3 rounded-3 py-3 light-bg"
          variant="contained"
          size="large"

        // onClick={() => doLogin()}
        >
          <AppleIcon className="me-3" /> Continue With Apple Id
        </Button>
      </a>
      <Link to={process.env.REACT_APP_BACKEND + "/auth/facebook"}>
        <Button
          fullWidth
          // style={{ backgroundColor: "#7b9858", color: "#fff" }}
          className="my-3 rounded-3 py-3 light-bg"
          variant="contained"
          size="large"

        // onClick={() => doLogin()}
        >
          <FacebookIcon className="me-3" /> Continue With Facebook
        </Button>
      </Link>
    </div>
  );
}
