import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser"
import { Link } from 'react-router-dom'
import { getAll } from "../../api";
import { getToday, substring } from "../../helpers/functions";
export default function ComingSoon() {

  const [currentPage, setCurrentPage] = useState(1);
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await getAll('/news/all', {
        page: currentPage,
        limit: 6,
      });
      if (res.status === 200) {
        setNewsList(res.data.data);
        // total(res.data.totalGames);
        // setTotalPages(Math.ceil(res.data.totalGames / ITEMS_PER_PAGE));
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false); // Data fetching complete, set loading state to false
  };


  useEffect(() => {
    getData()
  }, [])


  const dynamicContent = (content) => {
    if (content) {
      let newDyP = content.replace(/(<([^>]+)>)/ig, '')
      if (newDyP.length > 101) {
        return "<p>" + substring(newDyP, 75) + "</p>"
      } else {
        return "<p>" + newDyP + "</p>"
      }
    }
  }

  return (
    <>
      {
        newsList.length > 0 ? (
          <div className="container py-5">
            <h2>Top News</h2>
            <div className="row my-3 d-flex align-items-stretch">
              {newsList.map((game, index) => (

                <div className="col-lg-4 col-md-6 col-sm-11 mb-3" key={index}>
                  <div className="my-3">
                    <h2>{game.title}</h2>
                  </div>
                  <div className="my-2 image_url">
                    <img src={game.image} alt="" />
                  </div>

                  <div className="my-2 description">
                    {ReactHtmlParser(dynamicContent(game.description))}
                  </div>
                  <div className="my-2 read_more">
                    <Link className="btn btn-primary" to={game.url}>Read more</Link>
                  </div>
                  <div className="my-2 uploadedBy">
                    <span className="feature-text text-muted">
                      {getToday(game.createdAt)}
                    </span>
                    <span className="feature-text text-muted"> by {game.uploadedBy.username}</span>
                  </div>



                </div>
              ))}

            </div>
          </div>
        )
          : (
            <div></div>
          )
      }
    </>)

}
